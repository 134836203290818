import { useGetInvestmentProject } from 'hooks/queries/investment-project/investment-project';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import {
  InvestmentPlanRoutes,
  InvestmentProjectRoutes,
  Routes,
} from 'routes/constants';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Box, Grid, IconButton, Typography } from '@mui/material';

import { InvestmentPlanLayout } from 'components/investment-plan-layout/investment-plan-layout';
import { PageLoader } from 'components/page-loader/page-loader';
import { PaperLayout } from 'components/paper-layout/paper-layout';
import { Tabs } from 'components/tabs/tabs';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { DEFAULT_SCORE_VALUE } from './tab-panels/project-evaluation-panel/project-evaluation-form/constants';

export const InvestmentProjectPage: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { investmentProjectId, organizationId } = useParams();

  const { currentLanguage } = useIsEnLanguage();
  const { data: investmentProject, isLoading: investmentProjectIsLoading } =
    useGetInvestmentProject(investmentProjectId);

  if (investmentProjectIsLoading || !investmentProject) {
    return <PageLoader />;
  }

  const { name, evaluation } = investmentProject;

  const path = `/${Routes.ORGANIZATIONS}/${organizationId}/${Routes.INVESTMENT_PLAN}/${InvestmentPlanRoutes.INVESTMENT_PROJECTS}`;

  const getInvestmentProjectTabs = [
    {
      label: t(
        'investment_projects_page.investment_project.project_details.title',
      ),
      value: InvestmentProjectRoutes.PROJECT_DETAILS,
      path: `${path}/${investmentProjectId}/${InvestmentProjectRoutes.PROJECT_DETAILS}`,
    },
    {
      label: t('investment_projects_page.investment_project.evaluation.title'),
      value: InvestmentProjectRoutes.EVALUATION,
      path: `${path}/${investmentProjectId}/${InvestmentProjectRoutes.EVALUATION}`,
    },
    // {
    //   label: t(
    //     'investment_projects_page.investment_project.project_factsheet.title',
    //   ),
    //   value: InvestmentProjectRoutes.PROJECT_FACTSHEETS,
    //   path: `${path}/${investmentProjectId}/${InvestmentProjectRoutes.PROJECT_FACTSHEETS}`,
    // },
    // {
    //   label: t(
    //     'investment_projects_page.investment_project.feasibility_in_details.title',
    //   ),
    //   value: InvestmentProjectRoutes.FEASIBILITY_IN_DETAILS,
    //   path: `${path}/${investmentProjectId}/${InvestmentProjectRoutes.FEASIBILITY_IN_DETAILS}`,
    // },
  ];

  const contentElement = (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          color="primary"
          sx={{ mr: 1 }}
          onClick={() => navigate(path)}
        >
          <ArrowBackRoundedIcon />
        </IconButton>

        <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
          {t('investment_projects_page.investment_project.back_to_list')}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography fontSize={24} fontWeight={600} maxWidth={550}>
          {name[currentLanguage]}
        </Typography>

        <Box sx={{ backgroundColor: 'success.main', p: 1, borderRadius: 1 }}>
          <Typography sx={{ fontSize: 22, color: 'common.white' }}>
            {String(evaluation.score || DEFAULT_SCORE_VALUE)}
          </Typography>
        </Box>

        {/* TODO: uncomment once export is done */}
        {/*<Button*/}
        {/*  label="investment_projects_page.investment_project.export_project"*/}
        {/*  onClick={() => {}}*/}
        {/*  variant="outlined"*/}
        {/*  startIcon={<IosShareRoundedIcon />}*/}
        {/*/>*/}
      </Box>
    </>
  );

  return (
    <InvestmentPlanLayout content={contentElement}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <PaperLayout borderRadius={4}>
            <Tabs
              tabsData={getInvestmentProjectTabs}
              defaultValue={InvestmentProjectRoutes.PROJECT_DETAILS}
              validTabs={Object.values(InvestmentProjectRoutes)}
            />

            <Box>
              <Outlet context={investmentProject} />
            </Box>
          </PaperLayout>
        </Grid>
      </Grid>
    </InvestmentPlanLayout>
  );
};
