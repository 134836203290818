import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  InvestmentProject,
  InvestmentProjectMetadata,
} from 'models/investment-project.model';
import { ShortlistCriteriaData } from 'models/shortlist-criteria.model';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import ActionCellRenderer from './action-cell-renderer';
import { ChipCellRenderer } from './chip-cell-renderer';
import { LinkCellRenderer } from './link-cell-renderer';
import { ScoreCellRenderer } from './score-cell-renderer';
import { TotalScoreCellRenderer } from './total-score-cell-renderer';

export const useAGGridData = (
  investmentProjects: InvestmentProject[] | undefined,
  investmentProjectMetadata: InvestmentProjectMetadata | undefined,
  shortlistCriteriaData: Record<string, ShortlistCriteriaData> | undefined,
  openConfirmationDialog: (id: string) => void,
) => {
  const { t } = useTranslation();
  const { isEnLanguage, currentLanguage } = useIsEnLanguage();
  const rowData = useMemo(() => investmentProjects, [investmentProjects]);

  const { services, categories, themes } = investmentProjectMetadata || {};

  const {
    technical,
    financial,
    economic,
    environment,
    social,
    organizational,
    sustainability,
    strategicPlan,
    urgency,
    societal,
    politicalLegal,
    timing,
  } = shortlistCriteriaData || {};

  const columnDefs = useMemo(
    () =>
      [
        {
          headerName: 'ID',
          field: 'displayId',
          sortable: false,
          filter: false,
          width: 50,
          headerClass: 'header-bold',
          cellClass: 'cell-bold',
          pinned: 'left',
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.name',
          ),
          field: 'name',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          cellClass: 'cell-bold',
          pinned: 'left',
          cellRenderer: LinkCellRenderer,
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.created_at',
          ),
          field: 'createdAt',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          valueFormatter: (params) => {
            if (params.value) {
              return new Date(params.value).toLocaleDateString('uk-UA');
            }
            return '';
          },
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.service',
          ),
          field: 'service',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          cellRenderer: ChipCellRenderer,
          tooltipValueGetter: (params) => {
            return params.value.details?.[currentLanguage]
              ? params.value.details?.[currentLanguage]
              : '';
          },
          valueFormatter: (params) => {
            if (params.value.value && services) {
              const serviceName =
                services.find((s) => s._id === params.value.value)?.name ?? '';

              return t(`services.${serviceName}`);
            }
            return '';
          },
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.category',
          ),
          field: 'category',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          cellRenderer: ChipCellRenderer,
          valueFormatter: (params) => {
            if (params.value && categories) {
              const categoryName =
                categories.find((s) => s._id === params.value)?.name ?? '';

              return t(`categories.${categoryName}`);
            }
            return '';
          },
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.themes',
          ),
          field: 'theme',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          tooltipValueGetter: (params) => {
            return params.value.details?.[currentLanguage]
              ? params.value.details?.[currentLanguage]
              : '';
          },
          valueFormatter: (params) => {
            return params.value.value.length > 0
              ? params.value.value
                  .map((theme: any) => {
                    const themeName =
                      themes?.find((t) => t._id === theme)?.name ?? '';

                    return t(`themes.${themeName}`);
                  })
                  .join(', ')
              : '-';
          },
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.shortlist_score',
          ),
          field: 'evaluation',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          cellRenderer: TotalScoreCellRenderer,
          valueFormatter: (params) => params.value.score,
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.location',
          ),
          field: 'location',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          valueFormatter: (params) => {
            return params.value ? params.value[currentLanguage] : '-';
          },
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.description',
          ),
          field: 'description',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          valueFormatter: (params) => {
            return params.value ? params.value[currentLanguage] : '-';
          },
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.total_cost',
          ),
          field: 'totalCost',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          valueFormatter: (params) => {
            if (params.value != null) {
              return params.value.toLocaleString(
                isEnLanguage ? 'en-US' : 'uk-UA',
                {
                  maximumFractionDigits: 0,
                },
              );
            }
            return '-';
          },
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.donor_required',
          ),
          field: 'donorIsRequired',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          valueFormatter: (params) => {
            return params.value.value ? t(`${params.value.value}`) : '-';
          },
          tooltipValueGetter: (params) => {
            return params.value.details?.[currentLanguage]
              ? params.value.details?.[currentLanguage]
              : '';
          },
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.own_funding_available',
          ),
          field: 'ownFundingAvailable',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          tooltipValueGetter: (params) => {
            return params.value.details?.[currentLanguage]
              ? params.value.details?.[currentLanguage]
              : '';
          },
          valueFormatter: (params) => {
            return params.value.value
              ? t(`dropdown_options.${params.value.value}`)
              : '-';
          },
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.funding_discussed_with_donors',
          ),
          field: 'fundingDiscussedWithDonors',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          tooltipValueGetter: (params) => {
            return params.value.details?.[currentLanguage]
              ? params.value.details?.[currentLanguage]
              : '';
          },
          valueFormatter: (params) => {
            return params.value.value
              ? t(`dropdown_options.${params.value.value}`)
              : '-';
          },
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.project_phasing',
          ),
          field: 'projectPhasing',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          tooltipValueGetter: (params) => {
            return params.value.details?.[currentLanguage]
              ? params.value.details?.[currentLanguage]
              : '';
          },
          valueFormatter: (params) => {
            return params.value.value
              ? t(`dropdown_options.${params.value.value}`)
              : '-';
          },
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.feasibility_studies',
          ),
          field: 'feasibilityStudies',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          tooltipValueGetter: (params) => {
            return params.value.details?.[currentLanguage]
              ? params.value.details?.[currentLanguage]
              : '';
          },
          valueFormatter: (params) => {
            return params.value.value
              ? t(`dropdown_options.${params.value.value}`)
              : '-';
          },
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.feasibility_studies_can_start',
          ),
          field: 'feasibilityStudiesCanStart',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          tooltipValueGetter: (params) => {
            return params.value.details?.[currentLanguage]
              ? params.value.details?.[currentLanguage]
              : '';
          },
          valueFormatter: (params) => {
            return params.value.value
              ? t(`dropdown_options.${params.value.value}`)
              : '-';
          },
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.implementation_can_start',
          ),
          field: 'implementationCanStart',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          tooltipValueGetter: (params) => {
            return params.value.details?.[currentLanguage]
              ? params.value.details?.[currentLanguage]
              : '';
          },
          valueFormatter: (params) => {
            return params.value.value
              ? t(`dropdown_options.${params.value.value}`)
              : '-';
          },
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.urgency_level',
          ),
          field: 'urgencyLevel',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          tooltipValueGetter: (params) => {
            return params.value.details?.[currentLanguage]
              ? params.value.details?.[currentLanguage]
              : '';
          },
          valueFormatter: (params) => {
            return params.value.value
              ? t(`dropdown_options.${params.value.value}`)
              : '-';
          },
        },
        {
          headerName: t(
            'investment_projects_page.project_creation_dialog.priority_factsheet',
          ),
          field: 'priorityFactsheet',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          tooltipValueGetter: (params) => {
            return params.value.details?.[currentLanguage]
              ? params.value.details?.[currentLanguage]
              : '';
          },
          valueFormatter: (params) => {
            return params.value.value
              ? t(`dropdown_options.${params.value.value}`)
              : '-';
          },
        },
        {
          headerName: t('shortlist_criteria_page.technical.title', {
            weights: `, ${technical?.weights || 0}%`,
          }),
          field: 'evaluation.technical',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          cellRenderer: ScoreCellRenderer,
          hide: !technical?.isApplied,
          valueFormatter: (params) => params.value.technical,
        },
        {
          headerName: t('shortlist_criteria_page.financial.title', {
            weights: `, ${financial?.weights || 0}%`,
          }),
          field: 'evaluation.financial',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          cellRenderer: ScoreCellRenderer,
          hide: !financial?.isApplied,
          valueFormatter: (params) => params.value.financial,
        },
        {
          headerName: t('shortlist_criteria_page.economic.title', {
            weights: `, ${economic?.weights || 0}%`,
          }),
          field: 'evaluation.economic',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          cellRenderer: ScoreCellRenderer,
          hide: !economic?.isApplied,
          valueFormatter: (params) => params.value.economic,
        },
        {
          headerName: t('shortlist_criteria_page.environment.title', {
            weights: `, ${environment?.weights || 0}%`,
          }),
          field: 'evaluation.environment',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          cellRenderer: ScoreCellRenderer,
          hide: !environment?.isApplied,
          valueFormatter: (params) => params.value.environment,
        },
        {
          headerName: t('shortlist_criteria_page.social.title', {
            weights: `, ${social?.weights || 0}%`,
          }),
          field: 'evaluation.social',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          cellRenderer: ScoreCellRenderer,
          hide: !social?.isApplied,
          valueFormatter: (params) => params.value.social,
        },
        {
          headerName: t('shortlist_criteria_page.organizational.title', {
            weights: `, ${organizational?.weights || 0}%`,
          }),
          field: 'evaluation.organizational',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          cellRenderer: ScoreCellRenderer,
          hide: !organizational?.isApplied,
          valueFormatter: (params) => params.value.organizational,
        },
        {
          headerName: t('shortlist_criteria_page.sustainability.title', {
            weights: `, ${sustainability?.weights || 0}%`,
          }),
          field: 'evaluation.sustainability',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          cellRenderer: ScoreCellRenderer,
          hide: !sustainability?.isApplied,
          valueFormatter: (params) => params.value.sustainability,
        },
        {
          headerName: t('shortlist_criteria_page.strategic_plan.title', {
            weights: `, ${strategicPlan?.weights || 0}%`,
          }),
          field: 'evaluation.strategicPlan',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          cellRenderer: ScoreCellRenderer,
          hide: !strategicPlan?.isApplied,
          valueFormatter: (params) => params.value.strategicPlan,
        },
        {
          headerName: t('shortlist_criteria_page.urgency.title', {
            weights: `, ${urgency?.weights || 0}%`,
          }),
          field: 'evaluation.urgency',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          cellRenderer: ScoreCellRenderer,
          hide: !urgency?.isApplied,
          valueFormatter: (params) => params.value.urgency,
        },
        {
          headerName: t('shortlist_criteria_page.societal.title', {
            weights: `, ${societal?.weights || 0}%`,
          }),
          field: 'evaluation.societal',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          cellRenderer: ScoreCellRenderer,
          hide: !societal?.isApplied,
          valueFormatter: (params) => params.value.societal,
        },
        {
          headerName: t('shortlist_criteria_page.political_legal.title', {
            weights: `, ${politicalLegal?.weights || 0}%`,
          }),
          field: 'evaluation.politicalLegal',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          cellRenderer: ScoreCellRenderer,
          hide: !politicalLegal?.isApplied,
          valueFormatter: (params) => params.value.politicalLegal,
        },
        {
          headerName: t('shortlist_criteria_page.timing.title', {
            weights: `, ${timing?.weights || 0}%`,
          }),
          field: 'evaluation.timing',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          cellRenderer: ScoreCellRenderer,
          hide: !timing?.isApplied,
          valueFormatter: (params) => params.value.timing,
        },
        {
          headerName: 'Actions',
          field: 'actions',
          sortable: false,
          filter: false,
          headerClass: 'header-bold',
          width: 100,
          cellRenderer: ActionCellRenderer,
          cellRendererParams: (params: { data: { _id: any } }) => {
            return {
              onDelete: () => openConfirmationDialog(params.data._id),
            };
          },
          pinned: 'right',
        },
      ] as ColDef[],
    [
      t,
      services,
      categories,
      themes,
      currentLanguage,
      isEnLanguage,
      technical,
      financial,
      economic,
      environment,
      social,
      organizational,
      sustainability,
      strategicPlan,
      urgency,
      societal,
      politicalLegal,
      timing,
    ],
  );

  return { rowData, columnDefs };
};
