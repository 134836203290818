import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'routes/constants';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, IconButton, Typography } from '@mui/material';

import { useAuth0 } from '@auth0/auth0-react';

import { StyledLightTooltip } from 'components/investment-plan-layout/styles';
import { InvestmentProjectsTable } from 'components/investment-projects-table/investment-projects-table';
import { ProjectCreationDialog } from 'components/project-creation-dialog/project-creation-dialog';

import { UserModel } from 'models/user.model';

export const InvestmentProjectsPage: FC = () => {
  const { t } = useTranslation();

  const { organizationId } = useParams();
  const navigate = useNavigate();

  const { user } = useAuth0<UserModel>();

  const pathToHome = user?.app_metadata.isAdmin
    ? `/${Routes.ADMIN}/${Routes.ORGANIZATIONS}/${organizationId}`
    : `/${Routes.ORGANIZATIONS}/${organizationId}`;

  const [isOpenProjectDialogCreation, setIsOpenProjectDialogCreation] =
    useState(false);

  const renderTooltipTitle = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 2 }}>
      <Box>
        <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
          {t('investment_projects_page.tooltip.objective.title')}
        </Typography>

        <Typography sx={{ fontSize: 14 }}>
          {t('investment_projects_page.tooltip.objective.description')}
        </Typography>
      </Box>

      <Box>
        <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
          {t('investment_projects_page.tooltip.output.title')}
        </Typography>

        <Typography sx={{ fontSize: 14 }}>
          {t('investment_projects_page.tooltip.output.description')}
          <br />
          {t('investment_projects_page.tooltip.output.sub_description', '')}
        </Typography>
      </Box>
    </Box>
  );

  const renderContentElement = () => (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          color="primary"
          sx={{ mr: 2 }}
          onClick={() => navigate(pathToHome ?? '/')}
        >
          <ArrowBackRoundedIcon />
        </IconButton>

        <Typography sx={{ fontWeight: 700 }}>
          {t('shortlist_criteria_page.back_to_home')}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ ml: 7, fontSize: 24, fontWeight: 800 }}>
          {t('home_screen.investment_plan.investment_projects_page')}
        </Typography>

        <StyledLightTooltip
          title={renderTooltipTitle()}
          arrow
          placement="right-end"
        >
          <IconButton sx={{ ml: 1 }}>
            <InfoOutlinedIcon sx={{ color: 'graymodern.400' }} />
          </IconButton>
        </StyledLightTooltip>
      </Box>
    </Box>
  );

  return (
    <Box py={4} px={12}>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between' }}>
        {renderContentElement()}
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsOpenProjectDialogCreation(true)}
        >
          {t(
            'investment_projects_page.project_creation_dialog.add_new_project_btn',
          )}
        </Button>
      </Box>

      <InvestmentProjectsTable />

      {isOpenProjectDialogCreation && (
        <ProjectCreationDialog
          open={isOpenProjectDialogCreation}
          onClose={() => setIsOpenProjectDialogCreation(false)}
        />
      )}
    </Box>
  );
};
