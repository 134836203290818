import { Auth0ContextInterface } from '@auth0/auth0-react';

import {
  InvestmentProject,
  InvestmentProjectMetadata,
  PatchProject,
  ProjectCreatePayload,
} from 'models/investment-project.model';
import { UserModel } from 'models/user.model';

import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosUpdate,
} from './external-api.service';

export const investmentProjectService = {
  getInvestmentProjectMetadata: (auth: Auth0ContextInterface<UserModel>) => {
    return axiosGet<InvestmentProjectMetadata>(
      auth,
      'v2/investment-projects/meta-data',
    );
  },

  getProjects(auth: Auth0ContextInterface<UserModel>, organizationId: string) {
    return axiosGet<InvestmentProject[]>(
      auth,
      `v2/investment-projects/organization/${organizationId}`,
    );
  },

  getProjectById(auth: Auth0ContextInterface<UserModel>, projectId: string) {
    return axiosGet<InvestmentProject>(
      auth,
      `v2/investment-projects/${projectId}`,
    );
  },

  createProject: (
    auth: Auth0ContextInterface<UserModel>,
    project: ProjectCreatePayload,
  ) => {
    return axiosPost<InvestmentProject, ProjectCreatePayload>(
      auth,
      'v2/investment-projects',
      project,
    );
  },

  patchProject: (
    auth: Auth0ContextInterface<UserModel>,
    projectId: string,
    data: {
      projectField: PatchProject;
      fieldName: string;
      translatable: boolean;
    },
  ) => {
    return axiosUpdate<
      InvestmentProject,
      { projectField: PatchProject; translatable: boolean }
    >(auth, `v2/investment-projects/${projectId}`, data);
  },

  deleteProject: (
    auth: Auth0ContextInterface<UserModel>,
    projectId: string,
  ) => {
    return axiosDelete(auth, `v2/investment-projects/${projectId}`);
  },
};
