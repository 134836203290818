import { useGetInvestmentProjectMetadata } from 'hooks/queries/investment-project/investment-project';
import { FC, useContext, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useOutletContext, useParams } from 'react-router-dom';
import { InvestmentProjectRoutes } from 'routes/constants';

import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { InvestmentProject } from 'models/investment-project.model';

import { AppContext } from 'context';

import ProjectDetailsForm from './project-details-form';

const ProjectDetailsPanel: FC = () => {
  const { investmentProjectId } = useParams();
  const {
    commentsUniquePathId: { setCommentsUniquePathId },
  } = useContext(AppContext);

  const {
    data: investmentProjectMetadata,
    isLoading: investmentProjectMetadataIsLoading,
  } = useGetInvestmentProjectMetadata();
  const investmentProject: InvestmentProject = useOutletContext();
  const defaultValues = useMemo(
    () => ({
      name: {
        en: investmentProject.name.en,
        ua: investmentProject.name.ua,
      },
      service: {
        value: investmentProject.service.value,
        details: investmentProject.service.details,
      },
      category: investmentProject.category,
      totalCost: investmentProject.totalCost,
      theme: {
        value: investmentProject.theme.value,
        details: investmentProject.theme.details,
      },
      location: investmentProject.location,
      locationAttachments: investmentProject.locationAttachments,
      description: investmentProject.description,
      descriptionAttachments: investmentProject.descriptionAttachments,
      targetedBenefits: {
        value: investmentProject.targetedBenefits.value,
        details: investmentProject.targetedBenefits.details,
      },
      donorIsRequired: {
        value: investmentProject.donorIsRequired.value,
        details: investmentProject.donorIsRequired.details,
      },
      ownFundingAvailable: {
        value: investmentProject.ownFundingAvailable.value,
        details: investmentProject.ownFundingAvailable.details,
      },
      fundingDiscussedWithDonors: {
        value: investmentProject.fundingDiscussedWithDonors.value,
        details: investmentProject.fundingDiscussedWithDonors.details,
      },
      projectPhasing: {
        value: investmentProject.projectPhasing.value,
        details: investmentProject.projectPhasing.details,
      },
      feasibilityStudies: {
        value: investmentProject.feasibilityStudies.value,
        details: investmentProject.feasibilityStudies.details,
      },
      feasibilityStudiesAttachments:
        investmentProject.feasibilityStudiesAttachments,
      feasibilityStudiesCanStart: {
        value: investmentProject.feasibilityStudiesCanStart.value,
        details: investmentProject.feasibilityStudiesCanStart.details,
      },
      implementationCanStart: {
        value: investmentProject.implementationCanStart.value,
        details: investmentProject.implementationCanStart.details,
      },
      urgencyLevel: {
        value: investmentProject.urgencyLevel.value,
        details: investmentProject.urgencyLevel.details,
      },
      shortListScore: investmentProject.evaluation.score,
      priorityFactsheet: {
        value: investmentProject.priorityFactsheet.value,
        details: investmentProject.priorityFactsheet.details,
      },
    }),
    [investmentProject],
  );
  const methods = useForm({ defaultValues });

  useEffect(() => {
    if (!investmentProjectMetadataIsLoading) {
      const uniqueCommentsPath = `${investmentProjectId}${InvestmentProjectRoutes.PROJECT_DETAILS}`;

      setCommentsUniquePathId(uniqueCommentsPath);
    }
  }, [
    investmentProjectMetadataIsLoading,
    investmentProjectId,
    setCommentsUniquePathId,
  ]);

  if (
    investmentProjectMetadataIsLoading ||
    !investmentProjectMetadata ||
    !investmentProject
  ) {
    return <CircularProgress />;
  }
  return (
    <Box sx={{ p: 5 }}>
      <FormProvider {...methods}>
        <ProjectDetailsForm
          projectId={investmentProject._id}
          investmentProjectMetadata={investmentProjectMetadata}
          defaultValues={defaultValues}
        />
      </FormProvider>
    </Box>
  );
};

export default ProjectDetailsPanel;
